'use client'

import { Cell, Radio, Text } from '@vinted/web-ui'

import BestValueBadge from 'components/Bumps/BestValueBadge'
import useTranslate from 'hooks/useTranslate'

import { BumpOptionModel } from 'types/models'

type Props = {
  isSelected: boolean
  bumpOption: BumpOptionModel
  onSelect: () => void
  isBestValue?: boolean
}

const BumpOptionListItem = ({ onSelect, isSelected, bumpOption }: Props) => {
  const { id, description, isBestValue, countries } = bumpOption
  const translate = useTranslate('bump_items.a11y')
  const isSelectedText = translate(`${isSelected ? 'is_selected' : 'is_not_selected'}`)

  function handleSelect() {
    if (isSelected) return

    onSelect()
  }

  function renderTitle() {
    return (
      <>
        {isBestValue && <BestValueBadge />}
        <Text text={description} />
      </>
    )
  }

  function renderBody() {
    if (!countries?.length) return null

    return <Text type={Text.Type.Caption} theme="muted" text={countries} />
  }

  function renderSuffix() {
    return (
      <Cell
        styling={Cell.Styling.Narrow}
        suffix={
          <Radio
            checked={isSelected}
            name={`push_up_option-${id}`}
            value={id}
            onChange={() => undefined}
          />
        }
      />
    )
  }

  return (
    <Cell
      clickable
      title={renderTitle()}
      body={renderBody()}
      suffix={renderSuffix()}
      styling={Cell.Styling.Narrow}
      onClick={handleSelect}
      aria={{ 'aria-label': `${bumpOption.description} ${isSelectedText}` }}
    />
  )
}

export default BumpOptionListItem
