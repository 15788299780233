import { useEffect } from 'react'

import {
  prepareBumpOrder as prepareBumpOrderRequest,
  getBumpOrder as getBumpOrderRequest,
} from 'data/api'
import {
  transformGetBumpOrderResponse,
  transformPrepareBumpOrderResponse,
} from 'data/api/transformers/response'
import { ExtraServiceCheckoutModal } from 'constants/extra-service'

import useFetch from 'hooks/useFetch'
import useBumpCheckoutContext from 'hooks/useBumpCheckoutContext'

const useBumpOrder = () => {
  const { selectedItemIds, activeModal, setSelectedItemIds } = useBumpCheckoutContext()

  const {
    fetch: prepareBumpOrder,
    transformedData: preparedOrder,
    isLoading: isOrderPrepareLoading,
    error: prepareOrderError,
  } = useFetch(prepareBumpOrderRequest, transformPrepareBumpOrderResponse)

  const {
    fetch: getBumpOrder,
    transformedData: fetchedOrder,
    isLoading: isOrderGetLoading,
    error: getOrderError,
  } = useFetch(getBumpOrderRequest, transformGetBumpOrderResponse)

  useEffect(
    function setSelectedItemsIdsAfterOrderIsFetched() {
      if (!fetchedOrder) return
      if (selectedItemIds.length !== 0) return
      if (activeModal !== ExtraServiceCheckoutModal.Checkout) return

      const itemIds = fetchedOrder.pushUpOrderItems.map(({ itemId }) => itemId)
      setSelectedItemIds(itemIds)
    },
    [selectedItemIds, fetchedOrder, activeModal, setSelectedItemIds],
  )

  return {
    order: preparedOrder || fetchedOrder,
    isOrderLoading: isOrderPrepareLoading || isOrderGetLoading,
    orderError: prepareOrderError || getOrderError,
    prepareBumpOrder,
    getBumpOrder,
  }
}

export default useBumpOrder
